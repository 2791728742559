import { FormattedMessage, intlShape } from 'react-intl'
import { Link, withIntl } from '../../../i18n'
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { bannerIcon, fluidImage } from '../../../fragments/fragments'

import Addition from '../../../components/technical-benchmark/addition'
import Banner from '../../../components/reusables/banner'
import Centered from '../../../components/reusables/centered'
import Layout from '../../../components/layout'
import Multiplcation from '../../../components/technical-benchmark/multiplication'
import Solutions from '../../../components/reusables/solutions'
import Tabs from '../../../components/reusables/tabs'
import { injectIntl } from 'react-intl'

class TechnicalBenchmark extends Component {
  constructor(props) {
    super()
  }

  render() {
    const WrappedTabs = Tabs([<Addition />, <Multiplcation />])
    return (
      <Layout>
        {/* <Banner data={this.props.data} shade={'light'} />
        <Centered index="0" />
        <WrappedTabs /> */}
      </Layout>
    )
  }
}

export default withIntl(TechnicalBenchmark)

export const imagesQuery = graphql`
  query {
    bannerCube: file(relativePath: { eq: "hero/bannerCube@3x.png" }) {
      ...bannerIcon
    }
    cmt_wallet_hero: file(relativePath: { eq: "cmt-wallet-hero.png" }) {
      ...fluidImage
    }
    bitcoin: file(relativePath: { eq: "bitcoin.png" }) {
      ...fluidImage
    }
    blockchain: file(relativePath: { eq: "blockchain.png" }) {
      ...fluidImage
    }
    support: file(relativePath: { eq: "support.png" }) {
      ...fluidImage
    }
  }
`